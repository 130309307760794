import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import axiosInstance from "axiosInstance";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { useAsync } from "utils/hooks";
import * as auth from "auth-provider";
import Loading from "components/common/Loading";
import firebase from "firebase";
import firebaseConfig from "constants/firebaseconfig";

export const verifyFireBaseToken = async (body) => {
  try {
    const response = await axiosInstance.post(`/v1/auth/fire-verify`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = "AuthContext";

// var config = {
//   apiKey: "AIzaSyDXr56WLP2w_czbDtLbkejlb_IeGtXNwVU",
//   authDomain: "g5-influencer.firebaseapp.com",
//   projectId: "g5-influencer",
//   storageBucket: "g5-influencer.appspot.com",
//   messagingSenderId: "1076513382211",
//   appId: "1:1076513382211:web:6d914477b77227c0f2d9f6",
// };

async function initializeFirebase() {
  if (firebase?.apps.length) {
    return firebase.app();
  }
  const fire = await firebase.initializeApp(firebaseConfig);
  return fire;
}

const AuthenticationProvider = (props) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const firebaseRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const verifyAndFetchTokenFromServer = useCallback(async (fireApp) => {
    try {
      if (firebase.auth().currentUser) {
        setIsLoading(true);
        const token = await firebase.auth().currentUser.getIdToken(true);
        if (token) {
          // console.log(token);
          const response = await verifyFireBaseToken({ token });
          const { data, token: authToken } = auth.handleUserResponse(response);
          setAuthToken(authToken);
          setIsLoading(false);
          return setUser(data);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setUser(null);
      setAuthToken("");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const app = await initializeFirebase();
      // if (app) {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      // console.log(app, firebase.auth().currentUser);
      firebaseRef.current = app;
      // console.log("aaaah");
      // if (firebase.auth().currentUser) {
      //   await verifyAndFetchTokenFromServer(app);
      // } else {
      //   setIsLoading(false);
      //   console.log("no user found");
      // }
      // }
    })();
  }, [verifyAndFetchTokenFromServer]);

  useEffect(() => {
    const unsubscriber = firebase.auth().onAuthStateChanged(function (user) {
      // handle it
      if (!user) {
        setAuthToken(null);
        setUser(null);
        return setIsLoading(false);
      }
      // console.log("auth listner");
      verifyAndFetchTokenFromServer();
    });
    return () => {
      // console.log("uns");
      unsubscriber();
    };
  }, [verifyAndFetchTokenFromServer]);

  useEffect(() => {
    if (authToken) {
      const reqInterceptorId = axiosInstance.interceptors.request.use(
        function (config) {
          if (config.headers.skipAuthToken) {
            const { skipAuthToken, ...rest } = config.headers;
            config.headers = rest;
            return config;
          }
          config.headers = { ...config.headers, Authorization: authToken };
          return config;
        },
        function (error) {
          throw error;
        }
      );

      const resInterceptorId = axiosInstance.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          throw error;
        }
      );
      return () => {
        axiosInstance.interceptors.request.eject(reqInterceptorId);
        axiosInstance.interceptors.request.eject(resInterceptorId);
      };
    }
  }, [authToken]);

  const login = React.useCallback(
    (form) =>
      auth
        .login(form)
        .then(({ data, token }) => {
          setAuthToken(token);
          return setUser(data);
        })
        .catch((e) => {}),
    [setUser, setAuthToken]
  );

  // const onOTPVerify = React.useCallback((response) => {

  //   const { data, token } = auth.handleUserResponse(response);
  //   setAuthToken(token);
  //   setUser(data);
  // }, []);

  const register = React.useCallback(
    (form) =>
      auth.register(form).then(({ data, token }) => {
        setAuthToken(token);
        return setUser(data);
      }),
    [setUser, setAuthToken]
  );

  const logout = React.useCallback(() => {
    setUser(null);
    localStorage.clear();
    setAuthToken(null);
    firebase.auth().signOut();
  }, [setUser]);

  const updateUser = React.useCallback(
    (data) => {
      setUser(data);
    },
    [setUser]
  );

  const value = useMemo(
    () => ({
      user,
      login,
      updateUser,
      logout,
      register,
      authToken,
      verifyAndFetchTokenFromServer,
      userData: user,
    }),
    [
      login,
      logout,
      register,
      user,
      authToken,
      updateUser,
      verifyAndFetchTokenFromServer,
    ]
  );

  if (isLoading) {
    return <Loading />;
  }

  // if (isError) {
  //   return;
  // }

  // if (isSuccess) {
  return <AuthenticationContext.Provider value={value} {...props} />;
  // }
};

function useAuth() {
  const authentication = useContext(AuthenticationContext);
  return authentication;
}

export { AuthenticationProvider, useAuth };
