import * as React from "react";
import { useAuth } from "context/auth-context";
import Loading from "components/common/Loading";
import { BrowserRouter as Router } from "react-router-dom";

// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ "./authenticated-app")
// );
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

function App() {
  const { user } = useAuth();
  React.useEffect(() => {
    return window.location.assign("https://creators.galleri5.com/");
  }, []);
  return <div></div>;
}

export { App };
