import { useSnackbar } from "notistack";
import React from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const SnackbarAction = ({ snackKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button
      onClick={() => {
        closeSnackbar(snackKey);
      }}
    >
      <CloseIcon style={{ color: "#fff" }} />
    </Button>
  );
};

export default SnackbarAction;
