import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import "./index.css";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { AppProviders } from "context";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./themes/theme";
import { App } from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

const ErrorFallback = (
  <div className="error-container absolute left-0 right-0 top-0 bottom-0 bg-white flex flex-col m-0 items-center justify-center">
    <h2 className="heading2 mt-6 text-charcoal">Oops! We're sorry!</h2>
    <p className="mt-4 heading3 text-charcoal">
      Click{" "}
      <p
        onClick={() => window.location.reload()}
        className="underline text-blue-400"
      >
        here
      </p>{" "}
      to reload the App
    </p>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <AppProviders>
          <App />
        </AppProviders>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
