import React from "react";
import logoYellow from "assets/Images/Logo_symbol-yellow.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#5c5d5f",
  },
  barColorPrimary: {
    backgroundColor: "#FAC90E",
  },
})(LinearProgress);

const Loading = ({ text = "" }) => (
  <div className="loading-container bg-charcoal flex items-center justify-center flex-col">
    <img className="w-40 h-40" src={logoYellow} alt="" />
    <ColorLinearProgress className="w-64 mt-8" variant={"indeterminate"} />
    <br />
    <h3 className="heading3">{isNaN(parseInt(text)) ? text : ""}</h3>
  </div>
);

export default Loading;
