import React, { useState, useContext } from "react";
export const NavContext = React.createContext();
NavContext.displayName = "AuthContext";

const NavProvider = (props) => {
  const [infNavBarTitle, setInfNavBarTitle] = useState("");
  return (
    <NavContext.Provider
    {...props}
      value={{ infNavBarTitle, setInfNavBarTitle }}
    />
  );
};

function useNav() {
  const nav = useContext(NavContext);
  return nav;
}

export { NavProvider, useNav };
