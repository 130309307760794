import axios from "axios";

const apiURL =
  process.env.REACT_APP_API_BASE_URL || "https://dev.galleri5.co.in";

export const axiosInstance = axios.create({
  baseURL: apiURL,
});

export default axiosInstance;
