import { createMuiTheme } from "@material-ui/core";

const primary = {
  main: "#252525",
  contrastText: "#fef0f9",
};

const secondary = {
  main: "#FAC912",
  contrastText: "#252525",
};

const common = {
  black: "#252525",
};

const action = {
  active: "#FFF",
  hover: "rgba(250, 201, 18, 0.2)",
  hoverOpacity: 0.08,
  selected: "#FAC912",
  disabled: "rgba(0, 0, 0, 0.26)",
  disabledBackground: "rgba(0, 0, 0, 0.12)",
};

const opts = {
  common,
  primary,
  secondary,
  action,
};

export const theme = createMuiTheme({
  palette: opts,
});
