import * as React from "react";
import { SnackbarProvider } from "notistack";
import SnackbarAction from "../snackbarActions";
import { AuthenticationProvider } from "./auth-context";
import { NavProvider } from "./nav-context";
import GlobalModal from "./GlobalModal";
import { makeStyles } from "@material-ui/core";
// import {UserProvider} from './user-context'

const useStyles = makeStyles({
  snackbarSuccess: {
    backgroundColor: "#7cb7b5 !important",
  },

  snackbarError: {
    backgroundColor: "#ff6e6e !important",
  },
});

function AppProviders({ children }) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      style={{ zIndex: 99999999999999999 }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      action={(key) => <SnackbarAction snackKey={key} />}
      maxSnack={5}
      classes={{
        variantSuccess: classes.snackbarSuccess,
        variantError: classes.snackbarError,
      }}
    >
      <GlobalModal>
        <AuthenticationProvider>
          <NavProvider> {children}</NavProvider>
        </AuthenticationProvider>
      </GlobalModal>
    </SnackbarProvider>
  );
}

export { AppProviders };
